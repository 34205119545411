import { configureStore } from '@reduxjs/toolkit'
import { combineReducers, Reducer } from 'redux'
import { createInjectorsEnhancer } from 'redux-injectors'
import createSagaMiddleware from 'redux-saga'
import { featureReducer, featureSagas } from '../features'
import { modulesReducer, modulesSagas } from '../modules'
import { createRootSaga } from '../shared/services/sagas'
import { trackException } from '../shared/services/telemetry'
import { apiMiddlewares, apiReducers } from './api'
import { contextReducer, contextSagas } from './context'
import type { AppState } from './shared'
import { systemReducer } from './system'
import { uiReducer, uiSagas } from './ui'
import { userReducer, userSagas } from './user'

export type { AppState }

export const createReduxStore = () => {
  const sagaMiddleware = createSagaMiddleware({
    onError: async (e) => {
      trackException(e, { source: 'root-saga' })
      console.error('saga middleware error', e)
    }
  })

  const createReducer = (injectedReducers = {}): Reducer<AppState> =>
    combineReducers({
      ...injectedReducers,
      modules: modulesReducer,
      user: userReducer,
      features: featureReducer,
      system: systemReducer,
      ui: uiReducer,
      context: contextReducer,
      ...apiReducers
    })

  const store = configureStore({
    reducer: createReducer(),
    devTools: process.env.NODE_ENV !== 'production' && {
      shouldHotReload: false
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false
      }).concat(sagaMiddleware, ...apiMiddlewares),
    enhancers: (defaultEnhancers) => [
      createInjectorsEnhancer({ createReducer, runSaga: sagaMiddleware.run }),
      ...defaultEnhancers
    ]
  })

  const rootSagas = [
    ...uiSagas,
    ...userSagas,
    ...featureSagas,
    ...contextSagas,
    ...modulesSagas
  ]

  const rootSaga = createRootSaga('root-saga', rootSagas)

  sagaMiddleware.run(rootSaga)
  return store
}

export const store = createReduxStore()
